import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import Link from "../Link";

class BannerThin extends React.Component {

    render(){


        return (
            <div className="banner thin" data-bg-color={this.props.bgColor}>
                <Row type="flex" className="content" gutter={70}>
                    <Col xs={{span: 24}} md={{span: 18}} lg={{span: 16}}>
                        {this.props.header !== '' &&
                            <h2 data-color={this.props.headerColor}>{this.props.header}</h2>
                        }
                        {this.props.para.length !== '' &&
                            <div className="content-wrap" data-color={this.props.paraColor}>
                                <p>{this.props.para}</p>
                            </div>
                        }
                    </Col>
                    <Col xs={{span: 24}} md={{span: 3}} lg={{span: 8}}>
                        <Link className={"btn btn-" + this.props.btnColor} to={this.props.btnLink}>
                            <span>{this.props.btnText}</span>
                            <span className="icon arrow"></span>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BannerThin