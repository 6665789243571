import React, {Component} from 'react'
import './blog.less';
import Wrapper from '../components/Wrapper';
import {Col, Pagination, Row} from 'antd';
import { graphql } from 'gatsby';
import PageHeader from "../components/PageHeader";
import BannerThin from "../components/BannerThin";
import ListingItem from "../components/ListingItem";

class BlogWelsh extends Component {

    render() {

        const posts = this.props.data.allWordpressPost.edges;

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let slides = "";
        let headerImage = "";
        let depth = "";

        if(acfFields.header_images !== null && acfFields.header_images !== undefined) {

            if (acfFields.header_images.length > 1) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile.childImageSharp.fluid;
            depth = "full";

        } else {

            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile.childImageSharp.fluid;
            depth = "thin";

        }

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title = {acfFields.display_header}
                    videoID = {acfFields.header_video_id}
                    image = {headerImage}
                    slides = {slides}
                    headerColor = {acfFields.header_colour}
                    headerStyle = {acfFields.header_style}
                    depth={depth}
                    back = ""
                    date = ""
                />
                <div className="blog-listing">
                    <Row gutter={70}>
                        {posts && posts.map(({node}) => {
                            return (
                                <Col key={node.id} xs={{span: 24}} md={{span: 12}} lg={{span: 8}}>
                                    <ListingItem
                                        urlPath={"/cy/ein-blog/"+node.slug+"/"}
                                        color = "none"
                                        image={node.acf.image}
                                        title={node.title}
                                        date={node.humanDate}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                    <Row gutter={70}>
                        <Col xs={{span: 24}}>
                            <Pagination
                                current={this.props.pageContext.current}
                                total={this.props.pageContext.numPosts}
                                pageSize={this.props.pageContext.limit}
                                onChange={this.gotoPage}
                            />
                        </Col>
                    </Row>
                </div>
                <BannerThin
                    bgColor = {acfFields.thin_banner_background_colour}
                    headerColor = {acfFields.thin_banner_header_colour}
                    header = {acfFields.thin_banner_header}
                    paraColor = {acfFields.thin_banner_para_colour}
                    para = {acfFields.thin_banner_paragraph}
                    btnLink = {acfFields.thin_banner_button_link}
                    btnText = {acfFields.thin_banner_button_text}
                    btnColor = {acfFields.thin_banner_button_colour}
                />
            </Wrapper>
        )
    }
}

export const blogListQueryWelsh = graphql`
#  query blogListQuery($skip: Int!, $limit: Int!, $lang: String) {
#    allWordpressPost(filter: {translations: {current: {eq: $lang}}}, limit: $limit, skip: $skip, sort: { order: DESC, fields: [date] }){
query blogListQueryWelsh($lang: String) {
    wordpressPage(slug: { eq: "ein-blog" }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      translations {
        current
        en
        cy
      }
      acf {
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        
        header_style
        header_colour
        header_video_id
        header_images {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
              fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
        display_header
        show_back_link
        
        thin_banner_background_colour
        thin_banner_header_colour
        thin_banner_para_colour
        thin_banner_header
        thin_banner_paragraph
        thin_banner_button_colour
        thin_banner_button_text
        thin_banner_button_link
      }
    },
    allWordpressPost(filter: {translations: {current: {eq: $lang}}}, sort: { order: DESC, fields: [date] }){
      edges {
        node {
        id
        translations {
          current
          en
          cy
        }
        slug
        title
        date(formatString: "YYYY-MM-DDTHH:MM:SS")
        humanDate: date(formatString: "Do MMM")
        acf {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight:420, quality: 80, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }    
          }
        }
      }
    }
  }
 }
`;

export default BlogWelsh
